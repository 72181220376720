<template>
    <form>
        <input type='text' 
            :placeholder="placeholder"
            class="form-control" />
    </form>
</template>
<script>
var dateUtil = new DateUtil();
export default {
    props : {
        dateValue : {
            type: String,
            default: ''
        },
        newDateValue : {
            type: String,
            default: ''
        },
        placeholder : {
            type: String,
            default: ''
        },
        formatForm : {
            type: String,
            default: 'mmmm, d, yyyy'
        },
        disable : {
            type: Boolean,
            default: false
        },
    },
    data : function(){
        return {
            picker : null
        }
    },
    mounted : function(){
        this.buildUp();
        // Fire event on Init value
        this.$emit('onInitValue', this.dateValue);
        this.enableSetEvent();
    },
    ready : function(){
    },
    computed : {
    },
    watch : {
        dateValue : function(newVal, oldVal){
            this.disableSetEvent();
            this.setDate(newVal);
            this.enableSetEvent();
        },
        disable : function(newVal, oldVal){
            this.disableSetEvent();
            this.disableForm(newVal);
            this.enableSetEvent();
        }
    },
    methods : {
        buildUp : function(){
            var self = this;
            var selValue = this.dateValue;

            var inputDom = this.$el.getElementsByTagName('input')[0];
            var $dom = $(inputDom).pickadate({
                selectYears: true,
                selectMonths: true,
                format : this.formatForm,
            });
            var picker = this.picker = $dom.pickadate('picker');

            // selValue format will be YYYY-MM-DD
            if( selValue ){
                var mSec = dateUtil.localYyyyMmDd2GmtMsec(selValue);
                picker.set({
                    select : mSec
                });
            }
            // Val : Disable
            this.disableForm(this.disable);

            function hideClearWhenDisable(){
                // getClearButton
                var clearBtn = picker.$root.find('.picker__button--clear');
                if( self.disable ){
                    clearBtn.hide();
                }else{
                    clearBtn.show();
                }
            }
            picker.on('open', hideClearWhenDisable);
            picker.on('render', hideClearWhenDisable);
        },
        disableSetEvent : function(){
            this.picker.off('set');
        },
        enableSetEvent : function(){
            var self = this;
            this.picker.on('set', function(thingSet){
                self.onChangeByEvent.call(self, thingSet);
            })
        },
        disableForm : function(isDisable){
            this.picker.set('enable', !isDisable);
            this.picker.set('disable', isDisable);
        },
        onChangeByEvent : function(param){
            // param.select : 1572447600000
            // param.clear : null
            if( ! param ) return; // ignore
            if( param.hasOwnProperty('clear') ){
                this.$emit("onChange", '');
                return;
            }
            if( param.hasOwnProperty('select') ){
                var mSec  = param.select;
                if( mSec === NaN || isNaN(mSec) ){
                    this.$emit("onChange", '');
                    return;
                }
                var szYmd = dateUtil.gmtMsec2LocalYyyyMmDd(mSec);
                this.$emit("onChange", szYmd);
                return;
            }
            // Other case ignored
        },
        setDate : function(value){
            var mSec = dateUtil.localYyyyMmDd2GmtMsec(value);
            if( mSec === NaN || isNaN(mSec) || !mSec ){
                return;
            }
            this.picker.set({
                select : mSec
            });
        }
    },
}
</script>
<style scoped>

</style>